import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          image={`url(${mobileHero}) bottom/ cover no-repeat`}
          column
          justifyStart
          alignCenter
          textCenter
        >
          <CFImage
            mt="7vh"
            w="85%"
            src={heroText}
            alt="Umami Sushi Japanese Restaurant hero text"
            zIndex={98}
          />
          <CFView pulsate mt="20px">
            <OrderPickupButton />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) left/ cover no-repeat`}
          zIndex={90}
          column
          justifyCenter
          alignStart
        >
          <CFView column justifyCenter alignStart ml="10vw">
            <CFImage
              w="430px"
              src={heroText}
              alt="Umami Sushi Japanese Restaurant hero text"
              zIndex={98}
            />
            <CFView pulsate mt="30px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
