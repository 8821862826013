import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView>
          {/* <Parallax bgImage={mobileAbout} bgImageAlt="Entree Dish" strength={150}>
          <div style={{ height: '400px' }} />
        </Parallax> */}
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView m="0 auto" w="100%" maxWidth="1400px">
          <CFView
            h="400px"
            w="100%"
            image={`url(${about}) center / cover no-repeat`}
            style={{ backgroundAttachment: 'fixed' }}
            zIndex={90}
            column
            justifyCenter
            alignStart
          ></CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
